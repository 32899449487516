.App {
  text-align: center;
}

.videoyt1 {
  width: 34vh !important;
  height: 10vh !important;
}

.youtu {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

figure {
  break-inside: avoid;
}

/* .fila{
display:flex;
}

.uno, .dos{
  display: block;
} */

/* .fila:after{content: '';
  display: block;
  clear: both;

} */

.centro {
  margin: 0 auto;
  display: block;
}

@font-face {
  font-family: "Canela";
  src: url("Canela-Thin.woff2") format("woff2"),
    url("Canela-Thin.woff") format("woff"),
    url("Canela-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

.font1 {
  font-family: "Canela";
  font-weight: 100;
  font-style: normal;
}

.dropdown-menu {
  /* background-image: url('./ACU_2.jpg'); */
  background-size: 50%;
  border-color: rgba(0, 0, 0, 0) !important;
}

.dropdown-toggle:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #474540 !important;
}

html {
}

body {
  background-color: #e4ddcc !important;

  /* background-image: url('./UCP_pattern.jpg'); */
  color: #474540 !important;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dropdown {
  /* background-image: url('./ACU_2.jpg'); */
  background-size: 75%;
}

/* .App-logo08 {
  height: 10vmin;
  pointer-events: none;
  position:absolute;

} */

.App-logo02 {
  height: 10vmin;
  pointer-events: none;
  position: absolute;
}

.App-logo01 {
  height: 20vmin;
  pointer-events: none;
  position: absolute;
}

.App-logo0 {
  height: 30vmin;
  pointer-events: none;
  position: absolute;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  position: absolute;
}

.App-logo2 {
  height: 50vmin;
  pointer-events: none;
  position: absolute;
}
.App-logo2-1 {
  height: 55vmin;
  pointer-events: none;
  position: absolute;
}
.App-logo3 {
  height: 60vmin;
  pointer-events: none;
  position: absolute;
}
.App-logo3 {
  height: 70vmin;
  pointer-events: none;
  position: absolute;
}
.App-logo4 {
  height: 80vmin;
  pointer-events: none;
  position: absolute;
}
.App-logo5 {
  height: 90vmin;
  pointer-events: none;
  position: absolute;
}
.App-logo6 {
  height: 100vmin;
  pointer-events: none;
  position: absolute;
}
.App-logo7 {
  height: 110vmin;
  pointer-events: none;
  position: absolute;
}

.titulo {
  color: #474540 !important;
  font-size: 5rem;
  font-weight: bold;
  z-index: 1;
}

.chico {
  width: 20px;
  padding-bottom: 4px;
  padding-left: 4px;
}
.chico1 {
  width: 20px;
  padding-bottom: 4px;
  padding-right: 4px;
}

.navo {
  margin: 1rem;
  text-align: center;
  content-align: center;
  display: block;
  margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo02 {
    animation: App-logo-spin infinite 3s linear;
  }
  .App-logo01 {
    animation: App-logo-spin infinite 5s linear;
  }
  .App-logo0 {
    animation: App-logo-spin0 infinite 8s linear;
  }
  .App-logo {
    animation: App-logo-spin infinite 13s linear;
  }
  .App-logo2 {
    animation: App-logo-spin0 infinite 21s linear;
  }
  .App-logo2-1 {
    animation: App-logo-spin infinite 28s linear;
  }
  .App-logo3 {
    animation: App-logo-spin infinite 34s linear;
  }
  /* .App-logo3 {
    animation: App-logo-spin0 infinite 55s linear;
  } */
  .App-logo4 {
    animation: App-logo-spin0 infinite 89s linear;
  }
  .App-logo5 {
    animation: App-logo-spin infinite 144s linear;
  }
  .App-logo6 {
    animation: App-logo-spin0 infinite 233s linear;
  }
  .App-logo7 {
    animation: App-logo-spin infinite 377s linear;
  }
  /* body {
    animation: flash infinite 3s linear;
  } */
}

.App-div-one {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #474540;
}

.App-link {
  color: #61dafb;
}

.dropdown-item {
  color: #474540 !important;
  text-align: center !important;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
  background-image: none !important;
  color: #474540 !important;
}

.linkos:hover {
  background-color: rgba(0, 0, 0, 0.1);
  background-image: none;
  color: #474540 !important;
}
.linkos {
  /* background-image: url('./ACU_2.jpg'); */
  background-size: 50%;

  color: #474540 !important;
}

/* BOTON MENU */
.navbar-toggler {
  background-image: url("./logo-01.png");
  animation: App-logo-spin infinite 3s linear;
  border: none;
  border-color: rgba(0, 0, 0, 0);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.navbar-toggler:focus {
  border: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.navbar-toggler:focus-visible {
  border: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes App-logo-spin0 {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes flash {
  from {
    background-color: black;
  }
  to {
    background-color: #474540;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("./logo-01.png") !important;
}

.dropdown-menu {
  background-color: rgba(0, 0, 0, 0) !important;
}

.navbar-light .navbar-nav .nav-link.hover .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #474540 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #474540 !important;
}

.rain {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.rain.back-row {
  display: none;
  z-index: 1;
  bottom: 60px;
  opacity: 0.5;
}

body.back-row-toggle .rain.back-row {
  display: block;
}

.drop {
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 120px;
  pointer-events: none;
  animation: drop 0.5s linear infinite;
}

@keyframes drop {
  0% {
    transform: translateY(0vh);
  }
  75% {
    transform: translateY(90vh);
  }
  100% {
    transform: translateY(90vh);
  }
}

.stem {
  width: 1px;
  height: 60%;
  margin-left: 7px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.25)
  );
  animation: stem 0.5s linear infinite;
}

@keyframes stem {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.splat {
  width: 15px;
  height: 10px;
  border-top: 2px dotted rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  opacity: 1;
  transform: scale(0);
  animation: splat 0.5s linear infinite;
  display: none;
}

body.splat-toggle .splat {
  display: block;
}

@keyframes splat {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(0);
  }
  90% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.toggles {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.toggle {
  position: absolute;
  left: 20px;
  width: 50px;
  height: 50px;
  line-height: 51px;
  box-sizing: border-box;
  text-align: center;
  font-family: sans-serif;
  font-size: 10px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.toggle:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.toggle.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.splat-toggle {
  top: 20px;
}

.back-row-toggle {
  top: 90px;
  line-height: 12px;
  padding-top: 14px;
}

.single-toggle {
  top: 160px;
}

body.single-toggle .drop {
  display: none;
}

body.single-toggle .drop:nth-child(10) {
  display: block;
}

@media (max-width: 576px) {
  .fila {
    column-count: 1;
  }
  .youtu > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 350px;
    height: 200px;
  }

  .vimeovid > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 350px;
    height: 200px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .fila {
    column-count: 1;
  }

  .youtu > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 550px !important;
    height: 250px;
  }

  .vimeovid > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 550px !important;
    height: 305px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .fila {
    column-count: 2;
  }

  .youtu > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 550px !important;
    height: 305px;
  }

  .vimeovid > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 550px !important;
    height: 305px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .fila {
    column-count: 2;
  }

  .youtu > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 650px !important;
    height: 350px;
  }

  .vimeovid > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 700px !important;
    height: 350px;
  }
}

@media (min-width: 1200px) {
  .fila {
    column-count: 2;
  }
  .youtu > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 650px !important;
    height: 350px;
  }

  .vimeovid > iframe {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 700px !important;
    height: 350px;
  }
}
